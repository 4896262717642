import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from "@mui/material";
import HouseOfKai from './HouseOfKai';
import PlaceOfZen from './PlaceOfZen';

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
};

const AirbnbTab = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

const Airbnb = ({ loaded }) => {
  const [value, setValue] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return loaded ? (
    <Box sx={{ width: '100%' }}>
      <Typography
        style={{
          fontSize: "40px",
          // lineHeight: "16.8px",
          textAlign: "center",
          textTransform: "uppercase",
          fontWeight: 700,
          margin: "80px 0 20px 0",
          color: "white",
        }}
      >
        Our Airbnb Experiences
      </Typography>
      <Box style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '40px'
      }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs"
          indicatorColor="success"
        >
          <Tab
            style={{
              backgroundColor: value === 0 ? 'rgb(38, 128, 56)' : '#1D1D1F',
              color: value === 0 ? 'white' : 'rgba(255, 255, 255, 0.5)',
            }}
            label="House of Kai"
            {...a11yProps(0)}
          />
          <Tab
            style={{
              backgroundColor: value === 1 ? 'rgb(38, 128, 56)' : '#1D1D1F',
              color: value === 1 ? 'white' : 'rgba(255, 255, 255, 0.5)',
            }}
            label="Place of Zen"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <AirbnbTab value={value} index={1}>
        <PlaceOfZen loaded={loaded} />
      </AirbnbTab>
      <AirbnbTab value={value} index={0}>
        <HouseOfKai loaded={loaded} />
      </AirbnbTab>
    </Box>
  ) : null;
};

export default Airbnb;
