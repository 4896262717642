
import axios from "axios";
import { API_URL } from "../constants/urls";

export const fetchBlogs = async () => {
  try {
    const response = await axios.get(`${API_URL}/blogs?populate=*`);
    const blogs = response.data.data.map(item => ({
      id: item.id,
      ...item.attributes,
      BlogCarouselContent: item.attributes.BlogCarouselContent.data.map(media => ({
        id: media.id,
        ...media.attributes
      })),
      YouTubeLinks: item.attributes.YouTubeLinks.map(link => ({
        id: link.id,
        URL: link.URL
      }))
    }));
    return blogs;
  } catch (error) {
    console.error('Error fetching blogs:', error.message);
    throw error;
  }
};
