import { Box } from '@mui/material';
import React from 'react';
import { isMobile } from 'react-device-detect';
import BrowserFooter from './BrowserFooter';
import MobileFooter from './MobileFooter';
import useGetWindowDims from '../../hooks/useGetWindowDims';

const MainFooter = () => {
  const { width } = useGetWindowDims();

  return (
    <Box
      width="100%"
      maxWidth="100%" 
      display="flex"
      justifyContent="center"
    >
      {isMobile || width < 950 ? (
        <MobileFooter />
        ) : (
        <BrowserFooter />
      )}
    </Box>
  )
};

export default MainFooter;
