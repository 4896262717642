import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import 'typeface-roboto';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAfZZkAETQ2lv182LAOZCSYYawFFLwHrl8",
  authDomain: "kaizencapitalllc-1a1f0.firebaseapp.com",
  projectId: "kaizencapitalllc-1a1f0",
  storageBucket: "kaizencapitalllc-1a1f0.appspot.com",
  messagingSenderId: "1006935454799",
  appId: "1:1006935454799:web:fac6bb058cf8788176e63a",
  measurementId: "G-8Q98NMXYQM"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <App firebase={firebase} analytics={analytics} />
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
