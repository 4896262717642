import React, { useState, useContext } from "react";
import { Box, Typography, Button, Unstable_Grid2 as Grid, Modal } from "@mui/material";
import { CartContext } from "./CartContext";
import ProductCard from "./ProductCard";
import { productsArray } from "../../productsStore";
import { getProductData } from "../../productsStore";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'rgba(255, 255, 255, 0.9)', // Adjust background color to match site theme
  border: '1px solid #ddd', // Adjust border color to match site theme
  boxShadow: 24,
  p: 4,
  borderRadius: '8px', // Added border-radius for rounded corners
};

const CartProduct = ({ id, quantity }) => {
  const product = getProductData(id);
  return (
    <Box>
      <Typography>{product.title} Qty: {quantity}</Typography>
    </Box>
  );
};

const Shop = ({ loaded }) => {
  const cart = useContext(CartContext);
  const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const checkout = async () => {
    await fetch('http://localhost:4000/checkout', {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ items: cart.items })
    }).then((response) => {
      return response.json();
    }).then((response) => {
      if (response.url) {
        window.location.assign(response.url);
      }
    });
  };

  return loaded ? (
    <Box>
      <Grid xs={12} textAlign="center" mt="80px">
        <Typography
          style={{
            fontSize: "40px",
            textAlign: "center",
            textTransform: "uppercase",
            fontWeight: 700,
            marginBottom: "20px",
            color: "white",
          }}
        >
          Shop
        </Typography>
        <Button variant="contained" color="success" onClick={handleShow}>
          Cart ({productsCount} Items)
        </Button>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          padding: "20px",
        }}
        className="clothing-box"
      >
        {productsArray.map((product, index) => (
          <ProductCard key={index} product={product} />
        ))}
      </Box>

      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold', color: '#333' }}>
            Shopping Cart
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, fontFamily: 'Arial, sans-serif', color: '#555' }}>
            {productsCount > 0 ? (
              <>
                Items in your cart:
                {cart.items.map((currentProduct, idx) => (
                  <CartProduct key={idx} id={currentProduct.id} quantity={currentProduct.quantity} />
                ))}
                <Box mt={2} mb={2} style={{ fontWeight: 'bold' }}>
                  Total: ${cart.getTotalCost().toFixed(2)}
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    color="success"
                    sx={{
                      textAlign: 'center',
                      justifyContent: 'center',
                      width: '100%'
                    }}
                    onClick={checkout}
                  >
                    Purchase items
                  </Button>
                </Box>
              </>
            ) : (
              <Typography style={{ fontFamily: 'Arial, sans-serif', color: '#999' }}>There are no items in your cart</Typography>
            )}
          </Typography>
        </Box>
      </Modal>
    </Box>
  ) : null;
};

export default Shop;
