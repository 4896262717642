import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Stat from "./Stat";
import statBg1 from "../../assets/stats/1.png";
import statBg2 from "../../assets/stats/2.png";
import statBg3 from "../../assets/stats/3.png";
import statBg4 from "../../assets/stats/4.png";
import { fetchByTheNumbers } from "../../api/byTheNumbersApiClient";

const bgImages = [statBg1, statBg2, statBg3, statBg4];

const ByTheNumbers = ({ setLoaded, loaded }) => {
  const [byTheNumbersData, setByTheNumbersData] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const res = await fetchByTheNumbers();
      if (res && !byTheNumbersData) {
        setByTheNumbersData(res.sort((a, b) => a.id - b.id));
      }
    };
    fetch();
  });

  useEffect(() => {
    if (byTheNumbersData && !loaded.byTheNumbers) {
      setLoaded({ ...loaded, byTheNumbers: true });
    }
  }, [byTheNumbersData]);

  return byTheNumbersData ? (
    <Box
      style={{
        display: "flex",
        marginTop: "80px",
        marginBottom: "80px",
        flexDirection: "column",
      }}
    >
      <Box
        style={{
          fontSize: "40px",
          width: "100%",
          fontWeight: 700,
          textAlign: "center",
          marginBottom: "40px",
        }}
      >
        <Typography
          style={{
            fontSize: "40px",
            width: "100%",
            lineHeight: "48px",
            fontWeight: 700,
            textAlign: "center",
            color: 'white'
          }}
        >
          Kaizen by the numbers
        </Typography>
      </Box>

      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          alignItems: 'flex-end',
          // padding: '40px 0 0 0'
        }}
      >
        {byTheNumbersData && byTheNumbersData.map((data, i) => (
          <Stat bg={bgImages[i]} data={data} />
        ))}
        {/* <Stat bg={statBg1} data={byTheNumbersData && byTheNumbersData[0]} />
        <Stat bg={statBg2} data={byTheNumbersData && byTheNumbersData[1]} />
        <Stat bg={statBg3} data={byTheNumbersData && byTheNumbersData[2]} />
        <Stat bg={statBg4} data={byTheNumbersData && byTheNumbersData[3]} /> */}
      </Box>
    </Box>
  ) : null;
};

export default ByTheNumbers;
