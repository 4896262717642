import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { fetchProjectDetails } from '../../../api/projectDetailClient';
import { useParams } from 'react-router';

function ProjectsDetailView() {
  const { projectName } = useParams();
  const [proj, setProj] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchProjectDetails();
        const project = data.data.find(
          (p) => p.attributes.projectName.toLowerCase() === decodeURIComponent(projectName).toLowerCase()
        );
        setProj(project);
        setLoaded(true);
      } catch (error) {
        console.error('Error fetching project details:', error.message);
      }
    };

    fetchData();
  }, [projectName]);

  if (!loaded) return null;

  return (
    <Box width="100%" display="flex" justifyContent="center" p={2}>
      <Box maxWidth={1000} width="100%">
        <Grid container justifyContent="center" textAlign="center">
          <Grid item xs={12} mt={{ xs: 2, md: 8 }}>
            <Typography
              sx={{
                color: 'white',
                fontSize: { xs: '24px', md: '40px' },
                fontWeight: 700,
                textTransform: 'uppercase',
              }}
            >
              {proj?.attributes?.projectName || 'Project Name'}
            </Typography>
            <Box p="20px">
              <Typography
                sx={{
                  color: 'white',
                  fontSize: { xs: '18px', md: '30px' },
                  fontWeight: 700,
                  margin: '0 0 20px 0',
                }}
              >
                {proj?.attributes?.projectSubHeader || 'Project SubHeader'}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="center" flexDirection="column" mb={5}>
            {proj?.attributes?.photoOne?.data?.attributes?.formats?.medium?.url && (
              <Box display="flex" justifyContent="center" width="100%" mb={2}>
                <img
                  src={proj.attributes.photoOne.data.attributes.formats.medium.url}
                  alt={proj?.attributes?.photoOne?.data?.attributes?.alternativeText || 'Project Photo'}
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              </Box>
            )}
            {proj?.attributes?.photoOne?.data?.attributes?.caption && (
              <Typography
                sx={{
                  color: 'white',
                  fontSize: 'clamp(14px, 14px, 14px)',
                  textAlign: 'center',
                  fontWeight: 400,
                  lineHeight: '24px',
                  mt: 1,
                  mb: 1,
                }}
              >
                {proj.attributes.photoOne.data.attributes.caption}
              </Typography>
            )}

            {proj?.attributes?.subheadOne && (
              <Typography
                sx={{
                  color: 'white',
                  fontSize: 'clamp(14px, 14px, 14px)',
                  textAlign: 'center',
                  fontWeight: 400,
                  lineHeight: '24px',
                  textTransform: 'uppercase',
                  mb: 1,
                }}
              >
                {proj.attributes.subheadOne}
              </Typography>
            )}
            {proj?.attributes?.textBodyOne && (
              <Typography
                sx={{
                  color: 'white',
                  fontSize: 'clamp(14px, 14px, 14px)',
                  textAlign: 'center',
                  fontWeight: 400,
                  lineHeight: '24px',
                  mb: 5,
                  whiteSpace: 'pre-line',
                }}
              >
                {proj.attributes.textBodyOne}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="center" flexDirection="column" mb={5}>
            {proj?.attributes?.photoTwo?.data?.attributes?.formats?.medium?.url && (
              <Box display="flex" justifyContent="center" width="100%" mb={2}>
                <img
                  src={proj.attributes.photoTwo.data.attributes.formats.medium.url}
                  alt={proj?.attributes?.photoTwo?.data?.attributes?.alternativeText || 'Project Photo'}
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              </Box>
            )}
            {proj?.attributes?.photoTwo?.data?.attributes?.caption && (
              <Typography
                sx={{
                  color: 'white',
                  fontSize: 'clamp(14px, 14px, 14px)',
                  textAlign: 'center',
                  fontWeight: 400,
                  lineHeight: '24px',
                  mt: 1,
                  mb: 1,
                }}
              >
                {proj.attributes.photoTwo.data.attributes.caption}
              </Typography>
            )}

            {proj?.attributes?.subheadTwo && (
              <Typography
                sx={{
                  color: 'white',
                  fontSize: 'clamp(14px, 14px, 14px)',
                  textAlign: 'center',
                  fontWeight: 400,
                  lineHeight: '24px',
                  textTransform: 'uppercase',
                  mb: 1,
                }}
              >
                {proj.attributes.subheadTwo}
              </Typography>
            )}
            {proj?.attributes?.textBodyTwo && (
              <Typography
                sx={{
                  color: 'white',
                  fontSize: 'clamp(14px, 14px, 14px)',
                  textAlign: 'center',
                  fontWeight: 400,
                  lineHeight: '24px',
                  mb: 5,
                  whiteSpace: 'pre-line',
                }}
              >
                {proj.attributes.textBodyTwo}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="center" flexDirection="column" mb={5}>
            {proj?.attributes?.subheadThree && (
              <Typography
                sx={{
                  color: 'white',
                  fontSize: 'clamp(14px, 14px, 14px)',
                  textAlign: 'center',
                  fontWeight: 400,
                  lineHeight: '24px',
                  textTransform: 'uppercase',
                  mb: 1,
                }}
              >
                {proj.attributes.subheadThree}
              </Typography>
            )}
            {proj?.attributes?.textBodyThree && (
              <Typography
                sx={{
                  color: 'white',
                  fontSize: 'clamp(14px, 14px, 14px)',
                  textAlign: 'center',
                  fontWeight: 400,
                  lineHeight: '24px',
                  mb: 5,
                  whiteSpace: 'pre-line',
                }}
              >
                {proj.attributes.textBodyThree}
              </Typography>
            )}
          </Grid>

          {proj?.attributes?.footnote && (
            <Typography
              sx={{
                color: 'white',
                fontSize: 'clamp(14px, 14px, 14px)',
                textAlign: 'center',
                fontWeight: 300,
                lineHeight: '24px',
                mt: 1,
                mb: 3,
              }}
            >
              {proj.attributes.footnote}
            </Typography>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default ProjectsDetailView;
