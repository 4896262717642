import React from 'react';
import {
  Box,
  Typography,
  Unstable_Grid2 as Grid,
  Button,
} from "@mui/material";
import HeroCarousel from './HeroCarousel';

const imgUrls = [
  'https://kaizen-capital.s3.us-west-2.amazonaws.com/IMG_9929_31d9ec7e90.jpg?updated_at=2023-04-10T21:58:29.664Z',
  'https://kaizen-capital.s3.us-west-2.amazonaws.com/Entertainment_room_with_fireplace_4f03a93ba4.jpg?updated_at=2023-09-02T19:37:16.293Z',
  'https://kaizen-capital.s3.us-west-2.amazonaws.com/Dragon_s_Den_81e49db458.jpg?updated_at=2023-09-02T19:37:16.355Z',
  'https://kaizen-capital.s3.us-west-2.amazonaws.com/Bedroom_1_Queen_bec59c60f3.jpg?updated_at=2023-09-02T19:37:09.176Z',
  'https://kaizen-capital.s3.us-west-2.amazonaws.com/Photo_Dec_07_11_08_47_PM_d4b53d723a.jpg?updated_at=2023-09-02T19:37:23.282Z',
  'https://kaizen-capital.s3.us-west-2.amazonaws.com/pool_d2eb98c3f8.jpg?updated_at=2023-09-02T19:37:27.165Z',
  'https://kaizen-capital.s3.us-west-2.amazonaws.com/8_F401_AD_2_38_B6_49_A4_8_D41_412_EF_24_BAA_17_1_100_o_bf62391202.jpg?updated_at=2023-09-02T19:37:06.318Z',
];

const HouseOfKai = ({ loaded }) => {
  return loaded ? (
    <Box style={{ width: 'calc(100vw - 20px)', maxWidth: '1350px' }}>
      <Box
        display="flex"
        justifyContent="center"
      >
        <Grid container maxWidth={1000}>
          <Grid
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <HeroCarousel imgUrls={imgUrls} />
          </Grid>
          <Grid
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box p="20px">
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(14px, 14px, 14px)",
                  textAlign: "left",
                  fontWeight: 700,
                  lineHeight: "60px",
                  textTransform: "uppercase",
                }}
              >
                HOUSE OF KAI
              </Typography>
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(14px, 14px, 14px)",
                  textAlign: "left",
                  fontWeight: 400,
                  lineHeight: "24px",
                }}
                sx={{ whiteSpace: "pre-line" }}
              >
                Experience the blend of comfort and sophistication in our uniquely designed and furnished accommodation in Sacramento's serene Greenhaven area. Adjacent to the picturesque water levee, our property embodies tranquility.
              </Typography>
            </Box>

            <Box p="20px">
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(14px, 14px, 14px)",
                  textAlign: "left",
                  fontWeight: 700,
                  lineHeight: "60px",
                  textTransform: "uppercase",
                }}
              >
                The Space
              </Typography>
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(14px, 14px, 14px)",
                  textAlign: "left",
                  fontWeight: 400,
                  lineHeight: "24px",
                  marginBottom: "50px"
                }}
                sx={{ whiteSpace: "pre-line" }}
              >
                Immerse yourself in our luxurious 4-bedroom property, boasting a king bed and three queen beds. Additional sleeping space includes a queen Murphy bed in the loft and a versatile sectional sofa that converts into a queen bed in the living room.
                <br />
                <br />
                Each space is uniquely designed and furnished:
                <br />
                <ul>
                  <li>Master Bedroom: King bed</li>
                  <li>Bedrooms 1-3: Queen beds</li>
                  <li>Loft: Queen Murphy bed</li>
                  <li>Living Room: Sectional sofa convertible to Queen bed</li>
                  <li>Optional: Queen air mattresses</li>
                </ul>
                <br />
                Our property offers 2.5 well-appointed bathrooms:
                <br />
                <ul>
                  <li>1st floor: Powder room / half-bath</li>
                  <li>Master Bedroom: Ensuite full bathroom</li>
                  <li>2nd floor: Additional full bathroom</li>
                </ul> 
                <br />

                Entertain in style with a family room featuring an 85-inch Sony TV and a living room with a 65-inch LG TV, both equipped with state-of-the-art smart technology and sound bars with subwoofers.
                <br />
                <br />
                Create culinary delights in our fully equipped kitchen or on the outdoor BBQ grill.
                <br />
                <br />
                The backyard is a haven for relaxation with an unheated pool, a heated hot tub with jets (fits 4-6) and a fire pit for cozy evenings. Our outdoor space is a natural retreat, surrounded by mature trees.
              </Typography>
              <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  style={{
                    borderRadius: "50px",
                    textTransform: "capitalize",
                    color: 'white',
                    backgroundColor: 'rgb(38, 128, 56)',
                    marginBottom: '80px'
                  }}
                  href="https://www.airbnb.com/rooms/53137258"
                  target='_blank'
                >
                  Book Now
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  ) : null;
};

export default HouseOfKai;
