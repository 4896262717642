import React, { useEffect } from "react";
import { Box, useMediaQuery } from "@mui/material";
import MainFooter from "../MainFooter";
import { useLocation } from "react-router";
import WhoWeAreFooter from "./WhoWeAreFooter";
import PartnerWithUs from "../PartnerWithUs";

const Footer = ({ setLoaded, loaded }) => {
  const isMinBrowserWidth = useMediaQuery("(max-width:900px)");
  const { pathname } = useLocation();
  useEffect(() => {
    if (!loaded.footer) {
      setLoaded({ ...loaded, footer: true });
    }
  }, []);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // width: isMinBrowserWidth ? "100%" : "1350px",
        width: "calc(100vw - 20px)",
        maxWidth: "1350px",
        height: "min-content",
        background: "linear-gradient(154.97deg, #202A25 22.03%, #1D1D1F 65.78%), #FFFFFF"
      }}
    >
      {pathname === "/who-we-are" && <WhoWeAreFooter />}
      {/* {(pathname === '/' || pathname === '/testimonials') && ( */}
      <PartnerWithUs />
      {/* )} */}
      <MainFooter />
    </Box>
  );
};

export default Footer;
