import React, { useEffect } from 'react';
import { Paper, Box } from '@mui/material';

const loadYouTubeAPI = () => {
  return new Promise((resolve) => {
    if (window.YT) {
      resolve();
    } else {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      tag.onload = resolve;
      document.body.appendChild(tag);
    }
  });
};

const Item = ({ src, onVideoPlay, onVideoPause }) => {
  const videoId = src.includes('youtube.com') ? src.split('v=')[1] : null;

  useEffect(() => {
    if (!videoId) return;

    const onYouTubeIframeAPIReady = () => {
      const player = new window.YT.Player(`iframe-${videoId}`, {
        events: {
          onStateChange: (event) => {
            if (event.data === window.YT.PlayerState.PLAYING) {
              if (onVideoPlay) onVideoPlay();
            } else if (event.data === window.YT.PlayerState.PAUSED) {
              if (onVideoPause) onVideoPause();
            }
          },
        },
      });

      return () => player.destroy();
    };

    loadYouTubeAPI().then(() => {
      if (window.YT && window.YT.Player) {
        onYouTubeIframeAPIReady();
      } else {
        window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
      }
    });
  }, [videoId, onVideoPlay, onVideoPause]);

  return (
    <Paper
      sx={{
        width: '100%',
        maxWidth: '1350px',
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: 0,
        padding: 0,
        border: 0,
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }}
    >
      {videoId ? (
        <Box
          component="iframe"
          id={`iframe-${videoId}`}
          sx={{
            width: '100%',
            height: 'auto',
            aspectRatio: '16/9',
            border: 0,
          }}
          title="YouTube Video"
          src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1`}
          allowFullScreen
        />
      ) : (
        <Box
          component="img"
          sx={{
            width: '100%',
            height: 'auto',
            maxHeight: '700px',
            objectFit: 'contain',
            backgroundColor: 'transparent',
          }}
          alt="carousel item"
          src={src}
        />
      )}
    </Paper>
  );
};

export default Item;
