import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import quotation from "../../assets/quotation.png";

const ImageBlock = ({ imageUrl, containerStyle, imageContainer }) => {
  const isMinBrowserWidth = useMediaQuery("(max-width:980px)");
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        width: "30%",
        ...containerStyle,
      }}
    >
      {isMinBrowserWidth && <Box width="48px" />}
      <Box
        style={{
          height: "250px",
          width: "250px",
          ...imageContainer,
        }}
      >
        <img
          src={imageUrl}
          alt="avatar"
          style={{
            height: "inherit",
            width: "inherit",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      </Box>
    </Box>
  );
};

const TextBlock = ({ data, containerStyle }) => {
  const isMinBrowserWidth = useMediaQuery("(min-width:980px)");

  return (
    <Box width="70%" style={containerStyle}>
      <Box
        style={{
          width: "100%",
          maxWidth: "880px",
          display: "flex",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <Box
          style={{
            // justifyContent: "inherit",
            // alignItems: "inherit",
            height: "100%",
            // width: "20%",
          }}
        >
          <img
            width={!isMinBrowserWidth ? 32 : "inherit"}
            alt="quotation"
            src={quotation}
          />
        </Box>
        <Box>
          <Typography
            style={{
              fontSize: "18px",
              lineHeight: "24px",
              color: "white",
              whiteSpace: "pre-line",
            }}
          >
            {data.textBody}
          </Typography>
          {isMinBrowserWidth && <About data={data} />}
        </Box>
      </Box>
    </Box>
  );
};

const MobileVersionTestimonial = ({ data }) => {
  let width = 250;
  let height = 250;
  const isMinBrowserWidth = useMediaQuery("(max-width:980px)");
  const isMinBrowserWidth680 = useMediaQuery("(max-width:680px)");

  if (isMinBrowserWidth) {
    height = 180;
    width = 180;
  }

  if (isMinBrowserWidth680) {
    height = 80;
    width = 80;
  }

  return (
    <Box margin="clamp(20px, 20px, 80px)">
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="min-content"
      >
        <TextBlock data={data} containerStyle={{ width: "100%" }} />
        <ImageBlock
          imageUrl={data.image}
          containerStyle={{
            marginTop: "12px",
            width: "100%",
            justifyContent: "flex-start",
          }}
          imageContainer={{
            width: width + "px",
            height: height + "px",
          }}
        />
        <About
          data={data}
          containerStyle={{
            marginLeft: "48px",
          }}
        />
      </Box>
    </Box>
  );
};

const About = ({ data, containerStyle }) => {
  return (
    <Box marginTop="18px" style={containerStyle}>
      <Typography style={{ fontWeight: 700, color: "white" }}>
        {data.clientName}
      </Typography>
      <Typography style={{ color: "rgba(255, 255, 255, 0.5)" }}>
        {data.clientTitle
          ? data.clientTitle
          : data.clientName.includes("&")
          ? "Investors"
          : "Investor"}
      </Typography>
    </Box>
  );
};

const Testimonial = ({ id, data }) => {
  const isMinBrowserWidth = useMediaQuery("(min-width:980px)");

  if (!isMinBrowserWidth) return <MobileVersionTestimonial data={data} />;

  return (
    <Box margin="80px">
      {id % 2 === 1 ? (
        <Box display="flex" width="100%" height="min-content">
          <ImageBlock imageUrl={data.image} />
          <TextBlock data={data} />
        </Box>
      ) : (
        <Box display="flex" width="100%" height="min-content">
          <TextBlock data={data} />
          <ImageBlock imageUrl={data.image} />
        </Box>
      )}
    </Box>
  );
};

export default Testimonial;
