import React, { useState, useEffect } from "react";
import { Box, Button, Collapse, useMediaQuery } from "@mui/material";
import Hero from "./Hero";
import { fetchTestimonials } from "../../api/testimonialsApiClient";
import Testimonial from "./Testimonial";

const Testimonials = ({ loaded }) => {
  const [first5Testimonials, setFirst5Testimonials] = useState(null);
  const [restTestimonials, setRestTestimonials] = useState(null);
  const [viewMore, setViewMore] = useState(false);
  const isMinBrowserWidth = useMediaQuery("(max-width:980px)");

  useEffect(() => {
    const fetch = async () => {
      let res = await fetchTestimonials();
      if (res && !first5Testimonials) {
        res = res.sort((a, b) => a.id - b.id);
        const limit = isMinBrowserWidth ? 4 : 5;
        const initial = [];
        const rest = [];

        for (let i = 0; i < res.length; i++) {
          if (i < limit) {
            initial.push(res[i]);
          } else {
            rest.push(res[i]);
          }
        }

        setFirst5Testimonials(initial);
        if (rest.length) setRestTestimonials(rest);
      }
    };
    fetch();
  }, [isMinBrowserWidth]);

  return loaded.header ? (
    <Box display="flex" justifyContent="center">
      <Box
        style={{
          width: "calc(100vw - 20px)",
          maxWidth: "1350px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="Testimonials"
      >
        <Hero />
        {first5Testimonials &&
          first5Testimonials.map((t) => (
            <Testimonial key={t.id} id={t.id} data={t} />
          ))}
        <Collapse in={viewMore} timeout={1500}>
          <Box>
            {restTestimonials &&
              restTestimonials.map((t) => (
                <Testimonial key={t.id} id={t.id} data={t} />
              ))}
          </Box>
        </Collapse>
        {restTestimonials && (
          <Button
            variant="contained"
            style={{
              borderRadius: "50px",
              border: "1px solid #005713",
              color: 'white',
              backgroundColor: 'rgb(38, 128, 56)',
              textTransform: "capitalize",
              marginBottom: "80px",
            }}
            onClick={() => setViewMore(!viewMore)}
          >
            {viewMore ? "View Less" : "View More"}
          </Button>
        )}
      </Box>
    </Box>
  ) : null;
};

export default Testimonials;
