import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, Fade } from "@mui/material";
import { fetchHero } from "../../api/heroApiClient";
import useOnScreen from "../../hooks/useOnScreen";

const Hero = ({ setLoaded, loaded }) => {
  const boxRef = useRef(null);
  const isVisible = useOnScreen(boxRef, loaded.hero);

  const [heroData, setHeroData] = useState(null);
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setRendered(true);
    }
  }, [isVisible]);

  useEffect(() => {
    const fetch = async () => {
      const res = await fetchHero();
      if (res && !heroData) {
        setHeroData(res);
      }
    };
    fetch();
  });

  useEffect(() => {
    if (heroData && !loaded.hero) {
      setLoaded({ ...loaded, hero: true });
    }
  }, [heroData]);

  return heroData ? (
    <Box
      style={{ position: "relative", width: "100%", height: heroData.height }}
    >
      <Box
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1001,
          width: "100%",
          height: heroData.height,
          background: "rgba(0,0,0,0.4)",
        }}
      >
        <Fade in={rendered} timeout={1000}>
          <Box
            display="flex"
            flexDirection="inherit"
            alignItems="inherit"
            justifyContent="inherit"
            width="100%"
            height="100%"
            ref={boxRef}
          >
            <Typography
              style={{
                color: heroData.textColor,
                fontSize: "16px",
                lineHeight: "16.8px",
                textAlign: "center",
                textTransform: "uppercase",
                fontWeight: 700,
                marginBottom: "20px",
              }}
            >
              {heroData.subText}
            </Typography>
            <Typography
              style={{
                maxWidth: "1000px",
                width: "calc(100% - 180px)",
                color: heroData.textColor,
                fontSize: "clamp(1.5rem, 5vw, 2rem)",
                textAlign: "center",
                fontWeight: 700,
              }}
            >
              {heroData.mainBody}
            </Typography>
          </Box>
        </Fade>
      </Box>
      <img
        style={{
          display: "block",
          position: "absolute",
          maxHeight: heroData.height,
          width: "100%",
          objectFit: "cover",
          height: "100%",
          zIndex: 1000,
        }}
        alt="hero"
        src={heroData.backgroundImage}
      />
    </Box>
  ) : null;
};

export default Hero;
