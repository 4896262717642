import { Box, Typography, Link } from '@mui/material';
import React from 'react';
import logo from '../../assets/logo.png';
import { css } from '@emotion/react';

const styles = {
    container: css`
    display: flex;
    align-items: center;
    height: 306px;
    width: 100%;
    max-width: 100%;
    position: relative;
    z-index: 1000;
  `,
  companyName: css`
    font-size: 28px;
    font-weight: 700;
    color: white;
  `,
  text: css`
    line-height: 24px;
    color: white;
  `,
  link: css`
    color: white;
    text-decoration: none;
  `,
  linkContainer: css`
    display: flex;
    gap: 50px;
    height: 200px;
    margin-top: 18px;
  `,
  linkSection: css`
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
  `,
  linkSectionHeader: css`
    color: #01FF37;
    font-weight: 700;
    margin-bottom: 10px;
  `,
  copyrightWrapper: css`
    position: absolute;
    z-index: 1001;
    bottom: 30px;
    right: 40px; 
  `,
  copyrightText: css`
    font-size: 12px;
    color: white;
  `,
  infoContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
    margin-left: 70px;
    height: 100%;
  `,
  contactSection: css`
    display: flex;
    flex-direction: column;
    gap: 18px;
    height: 200px;
  `
}

const BrowserFooter = () => {
return (
  <Box sx={styles.container}>
    <Box sx={styles.infoContainer}>
      <img src={logo} alt="logo" style={{ height: "200px" }} />
      <Box sx={styles.contactSection}>
        <Typography sx={styles.companyName}>Kaizen Capital, LLC</Typography>
        <Box>
          <Typography sx={styles.text}>P.O. Box 161751</Typography>
          <Typography sx={styles.text}>Sacramento, CA 95816</Typography>
        </Box>
        <Typography sx={styles.text}>
          <Link
            href="mailto:info@kaizencapitalllc.com"
            sx={styles.link}
          >
            info@kaizencapitalllc.com
          </Link>
        </Typography>
      </Box>
      <Box sx={styles.linkContainer}>
        <Box sx={styles.linkSection}>
          <Typography sx={styles.linkSectionHeader}>SITE MENU</Typography>
          <Typography>
            <Link href="/" sx={styles.link}>Home</Link>
          </Typography>
          <Typography>
            <Link href="/who-we-are" sx={styles.link}>About Us</Link>
          </Typography>
          <Typography>
            <Link href="/testimonials" sx={styles.link}>Testimonials</Link>
          </Typography>
        </Box>
        <Box sx={styles.linkSection}>
          <Typography sx={styles.linkSectionHeader}>FOLLOW US</Typography>
          <Typography>
            <Link
              href="https://youtube.com/nanunlimited"
              sx={styles.link}
              target="_blank"
              rel='noopener'
            >
              YouTube
            </Link>
          </Typography>
          <Typography>
            <Link
              href="https://instagram.com/kaizencapital"
              sx={styles.link}
              target="_blank"
              rel='noopener'
            >
              Instagram
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
    <Box sx={styles.copyrightWrapper}>
      <Typography sx={styles.copyrightText}>
        {`© ${new Date().getFullYear()} Kaizen Capital, LLC`}
      </Typography>
    </Box>
  </Box>
  )
};

export default BrowserFooter;
