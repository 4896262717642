import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import {
  TextField,
  Select,
  Chip,
  Box,
  FormLabel,
  MenuItem,
  Button,
  Typography
} from '@mui/material';
import { css } from '@emotion/react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 570px;
    width: 512px;
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 6px;
  `,
  innerContainer: css`
    display: inherit;
    flex-direction: inherit;
    align-items: inherit;
    height: 100%;
    width: 100%;
    padding: 16px !important;
    box-sizing: border-box;
  `,
  textField: css`
    margin-top: 5px;
  `,
  fieldLabel: css`
    color: white;
    font-size: 13px;
    font-weight: 500;
  `,
  muiInput: css`
    background-color: white;
  `,
  twoFieldRow: css`
    display: flex;
    gap: 16px;
    width: 100%;
    justify-content: center;
    margin-bottom: 16px;
  `,
  controlledField: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  dropdown: css`
    background-color: white;
    margin-top: 5px;
  `,
  investmentGoals: css`
    display: flex;
    gap: 8px;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 5px;
    margin-bottom: 16px;
  `,
  chip: css`
    border: 1px solid white;
    color: white;
    font-weight: 700;
  `,
  selectedChip: css`
    border: 1px solid rgb(36, 255, 42);
    background-color: rgb(36, 255, 42);
    font-weight: 700;
    color: black;
    &:hover {
      background-color: rgb(36, 255, 42);
    };
  `,
  submitContainer: css`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    height: 70px;
    align-items: flex-end;
  `,
  submitButton: css`
    border-radius: 50px;
    border: 1px solid white;
    color: white;
    text-transform: capitalize;
    &:hover {
      color: rgb(36, 255, 42);
      border: 1px solid rgb(36, 255, 42);
    };
  `,
  placeholder: css`
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.4);
  `,
  border: css`
    border-color: rgb(36, 255, 42);
  `,
  submittedContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 60px;
    height: 100%;
    gap: 24px;
  `
};

const ControlledField = ({
  id,
  control,
  errors,
  name,
  label,
  required,
  disabled,
  placeholder,
  multiline,
  rows
}) => {
  return (
    <Box sx={styles.controlledField}>
      <FormLabel
        disabled={disabled}
        required={required}
        sx={styles.fieldLabel}
      >
        {label}
      </FormLabel>
      <Controller
        control={control}
        name={name}
        rules={{
          required,
          maxLength: 255
        }}
        render={({ field: { onChange, ...props } }) => {
          return (
            <TextField
              {...props}
              id={id}
              margin="none"
              size="small"
              sx={styles.textField}
              InputProps={{ sx: styles.muiInput }}
              hiddenLabel={true}
              onChange={onChange}
              error={errors && errors[name]}
              fullWidth
              placeholder={placeholder}
              multiline={multiline}
              rows={rows}
            />
          )
        }}
      />
    </Box>
  )
};

const INVESTMENT_RANGE_OPTIONS = [
  'Less than $50,000',
  '$50,000 - $100,000',
  '$100,000 - $200,000',
  '$200,000 - $500,000',
  'More than $500,000'
];

const INVESTMENT_TIMELINE_OPTIONS = [
  'Immediately',
  'Within the next few weeks',
  'Within the next few months',
  'Within the next year'
];

const SelectPlaceholder = ({ placeholder, show }) => {
  return show ? (
    <Typography sx={styles.placeholder} color="disabled">{placeholder}</Typography>
  ) : null;
};

const ControlledSelect = ({
  control,
  errors,
  name,
  label,
  required,
  disabled,
  options
}) => {
  const watchSelection = useWatch({ control, name });

  return (
    <Box sx={styles.controlledField}>
      <FormLabel
        disabled={disabled}
        required={required}
        sx={styles.fieldLabel}
      >
        {label}
      </FormLabel>
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { onChange, ...props } }) => (
          <Select
            onChange={onChange}
            value={props.value}
            margin="none"
            size="small"
            error={errors && errors[name]}
            sx={styles.dropdown}
            startAdornment={<SelectPlaceholder
              placeholder="Select a range"
              show={watchSelection === ""}
            />}
          >
            {options.map((option, i) => {
              return (
                <MenuItem key={i} value={option}>{option}</MenuItem>
              )
            })}
          </Select>
        )}
      />
    </Box>
  )
};

const INVESTMENT_GOALS_OPTIONS = [
  'Distributions',
  'Growth',
  'High returns',
  'Equity',
  'Stability',
  'Long-term',
  'Short-term'
];

const InvestmentGoals = ({ name, label, options, watch, setValue }) => {
  const selectedOptions = watch('investmentGoals');
  return (
    <Box width="100%" display="flex" flexDirection="column">
      <FormLabel sx={styles.fieldLabel}>
        {label}
      </FormLabel>
      <Box sx={styles.investmentGoals}>
        {options.map((option, i) => {
          return (
            <Chip
              key={option}
              sx={selectedOptions[`${i}`] ? styles.selectedChip : styles.chip}
              label={option}
              onClick={() => {
                const newSelectedOptions = { ...selectedOptions };
                if (newSelectedOptions[`${i}`]) {
                  delete newSelectedOptions[`${i}`];
                } else {
                  newSelectedOptions[`${i}`] = option;
                }
                setValue(name, newSelectedOptions);
              }}
            />
          )
        })}
      </Box>
    </Box>
  )
};

const BrowserForm = ({ formMethods, onSubmit, submitted }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue  
  } = formMethods;

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)}>
      <Box sx={styles.container}>
        {!submitted ? (
          <Box sx={styles.innerContainer}>
            <Box sx={styles.twoFieldRow}>
              <ControlledField
                id="first-name"
                control={control}
                errors={errors}
                name="firstName"
                label="First name"
                required={true}
                placeholder="John"
              />
              <ControlledField
                id="last-name"
                control={control}
                errors={errors}
                name="lastName"
                label="Last name"
                required={true}
                placeholder="Adams"
              />
            </Box>
            <Box sx={styles.twoFieldRow}>
              <ControlledField
                id="phone-number"
                control={control}
                errors={errors}
                name="phoneNumber"
                label="Phone number"
                required={true}
                placeholder="123-456-7890"
              />
              <ControlledField
                id="email-address"
                control={control}
                errors={errors}
                name="emailAddress"
                label="Email address"
                required={true}
                placeholder="johnadams@gmail.com"
              />
            </Box>
            <InvestmentGoals
              control={control}
              id="investment-goals"
              name="investmentGoals"
              label="Select your investment goals:"
              options={INVESTMENT_GOALS_OPTIONS}
              watch={watch}
              setValue={setValue}
            />
            <Box sx={styles.twoFieldRow}>
              <ControlledSelect
                id="investment-range"
                control={control}
                errors={errors}
                name="investmentRange"
                label="How much do you want to invest?"
                required={true}
                options={INVESTMENT_RANGE_OPTIONS}
                placeholder="Select a range"
              />
              <ControlledSelect
                id="investment-timeline"
                control={control}
                errors={errors}
                name="investmentTimeline"
                label="When will you be ready to invest?"
                required={true}
                options={INVESTMENT_TIMELINE_OPTIONS}
                placeholder="Select a range"
              />
            </Box>
            <ControlledField
              id="additional-details"
              control={control}
              errors={errors}
              name="additionalDetails"
              label="Additional details"
              placeholder="Enter any additional details here."
              multiline
              rows={4}
            />
            <Box sx={styles.submitContainer}>
              <Button
                variant="outlined"
                sx={styles.submitButton}
                type="submit"
                form="form"
              >
                Submit
              </Button>
            </Box>
          </Box>
        ) : (
          <Box sx={styles.submittedContainer}>
            <Typography
              fontSize="40px"
              fontWeight={700}
              color="white"
              textAlign="center"
            >
              Your information has been submitted!
            </Typography>
            <CheckCircleOutlineIcon style={{ fill: 'white', fontSize: '40px' }} />
            <Typography color="white" textAlign="center">
              We will reach out to you soon.
            </Typography>
          </Box>
        )}
      </Box>
    </form>
  );
};

export default BrowserForm;
