import React from "react";
import { Box, Typography } from "@mui/material";

const Hero = ({ setLoaded, loaded }) => {
  return (
    <Box style={{ position: "relative", width: "100%", height: 560 }}>
      <Box
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1001,
          width: "100%",
          height: 560,
          background: "rgba(0,0,0,0.4)",
        }}
      >
        {/* <Fade in={rendered} timeout={1000}> */}
        <Box
          display="inherit"
          flexDirection="inherit"
          alignItems="inherit"
          justifyContent="inherit"
          width="100%"
          height="100%"
        >
          <Typography
            style={{
              color: "#FFFFFF",
              fontSize: "14px",
              lineHeight: "16.8px",
              textAlign: "center",
              textTransform: "uppercase",
              fontWeight: 700,
              marginBottom: "20px",
            }}
          >
            Word of Mouth Matters
          </Typography>
          <Typography
            style={{
              maxWidth: "1000px",
              width: "calc(100% - 180px)",
              color: "#FFFFFF",
              fontSize: "clamp(1.8rem, 5vw, 3rem)",
              textAlign: "center",
              fontWeight: 700,
            }}
          >
            "Nan possesses a great mind, real estate makes so much sense to me
            when he's involved in the process."
          </Typography>
        </Box>
        {/* </Fade> */}
      </Box>
      <img
        style={{
          display: "block",
          position: "absolute",
          maxHeight: 560,
          width: "100%",
          objectFit: "cover",
          height: "100%",
          zIndex: 1000,
        }}
        alt="hero"
        src="https://kaizen-capital.s3.us-west-2.amazonaws.com/testimonials_hero_3bdc16c0b0.jpg?updated_at=2023-02-15T04:51:02.131Z"
      />
    </Box>
  );
};

export default Hero;
