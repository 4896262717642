import { useState, useMemo, useEffect } from 'react';

export default function useOnScreen(ref, loaded) {

  const [isIntersecting, setIntersecting] = useState(false)

  const observer = useMemo(() => new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  ), [ref]);


  useEffect(() => {
    if (loaded) {
      observer.observe(ref.current);
      return () => observer.disconnect();
    }
  }, [loaded]);

  return isIntersecting;
}