import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Grid, Tab, Tabs, MenuItem, Select, useMediaQuery, Menu } from "@mui/material";
import { fetchProjectDetails } from '../../../api/projectDetailClient';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';

const ProjectsListView = () => {
  const [projects, setProjects] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [categoryFilter, setCategoryFilter] = useState('All');
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchProjectDetails();
        setProjects(data.data);
        setLoaded(true);
      } catch (error) {
        console.error('Error fetching project details:', error.message);
      }
    };

    fetchData();
  }, []);

  const handleLearnMoreClick = (projectName) => {
    navigate(`/projects/${encodeURIComponent(projectName.toLowerCase())}`);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setCategoryFilter('All');
  };

  const handleMobileCategoryChange = (event) => {
    setCategoryFilter(event.target.value);
  };

  const handleDesktopCategoryChange = (event, newValue) => {
    setCategoryFilter(newValue);
  };

  const renderProjectCards = (projectsToRender) => (
    <Grid container spacing={3} justifyContent="center">
      {projectsToRender.map((proj) => (
        <Grid item xs={12} sm={6} md={4} key={proj.id}>
          <Card sx={{
            maxWidth: 345,
            m: 2,
            boxShadow: 3,
            borderRadius: 2,
            margin: 'auto',
            paddingBottom: '10px',
            backgroundColor: 'rgba(100, 100, 100, 0.35)',
            color: 'white',
          }}>
            <CardMedia
              sx={{ height: 200, borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
              image={proj?.attributes?.photoOne?.data?.attributes?.formats?.medium?.url}
              title={proj?.attributes?.projectName}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold', color: 'white' }}>
                {proj.attributes.projectName}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  mb: 2,
                  color: 'white'
                }}
              >
                {proj?.attributes?.textBodyOne}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                variant="outlined"
                color="success"
                onClick={() => handleLearnMoreClick(proj.attributes.projectName)}
                sx={{ fontWeight: 'bold', width: '50%' }}
              >
                Details
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  const filteredProjects = projects.filter((proj) => {
    const projectCompleted = tabValue === 0 ? proj.attributes.completedProject === true : proj.attributes.completedProject === false;
    const projectMatchesCategory = categoryFilter === 'All' || proj.attributes.category === categoryFilter;
    return projectCompleted && projectMatchesCategory;
  });

  return loaded ? (
    <Box sx={{ width: '100%', padding: '20px' }}>
      <Typography
        style={{
          fontSize: "40px",
          textAlign: "center",
          textTransform: "uppercase",
          fontWeight: 700,
          marginBottom: "20px",
          color: "white",
        }}
      >
        Our Projects
      </Typography>
      <Box style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px'
      }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="project tabs"
          indicatorColor="success"
        >
          <Tab
            value={0}
            style={{
              backgroundColor: tabValue === 0 ? 'rgb(38, 128, 56)' : '#1D1D1F',
              color: tabValue === 0 ? 'white' : 'rgba(255, 255, 255, 0.5)',
            }}
            label="Completed"
          />
          <Tab
            value={1}
            style={{
              backgroundColor: tabValue === 1 ? 'rgb(38, 128, 56)' : '#1D1D1F',
              color: tabValue === 1 ? 'white' : 'rgba(255, 255, 255, 0.5)',
            }}
            label="Ongoing"
          />
        </Tabs>
      </Box>
      <Box sx={{ width: '90%',
          padding: '20px',
          overflowX: 'auto',
          display: 'flex',
          justifyContent: 'center',
          overflowY: 'hidden' }}>
        {isMobile ? (
          <Select
            value={categoryFilter}
            onChange={handleMobileCategoryChange}
            fullWidth
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: 'rgba(80, 80, 80, 0.95)',
                  color: 'white',
                },
              },
            }}
            sx={{
              backgroundColor: 'rgba(100, 100, 100, 0.35) !important',
              color: 'white',
              marginBottom: '20px',
              '& .MuiSelect-icon': {
                color: 'white',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'rgb(38, 128, 56) !important',
                },
                '&:hover fieldset': {
                  borderColor: 'rgb(38, 128, 56) !important',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'rgb(38, 128, 56) !important',
                },
              },
              '& .MuiSelect-select': {
                color: 'white',
              },
              '& .MuiSelect-select.MuiSelect-select': {
                color: 'white !important',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgb(38, 128, 56) !important',
              },
            }}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="PARCEL MAP/SUBDIVISION/CONDO-MAPS">Parcel Map/Subdivision/Condo-Maps</MenuItem>
            <MenuItem value="COMMUNITY DEVELOPMENT">Community Development</MenuItem>
            <MenuItem value="ADU DEVELOPMENT">ADU Development</MenuItem>
            <MenuItem value="ADU CONVERSION">ADU Conversion</MenuItem>
            <MenuItem value="AIRBNB">Airbnb</MenuItem>
          </Select>
        ) : (
          <Tabs
            value={categoryFilter}
            onChange={handleDesktopCategoryChange}
            aria-label="category tabs"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              marginBottom: '20px',
              '& .MuiTabs-indicator': {
                backgroundColor: 'rgb(38, 128, 56)',
              },
              '& .MuiTab-root': {
                color: 'rgba(255, 255, 255, 0.5)',
                '&.Mui-selected': {
                  color: 'rgb(38, 128, 56)',
                },
              },
            }}
          >
            <Tab value="All" label="All" />
            <Tab value="PARCEL MAP/SUBDIVISION/CONDO-MAPS" label="Parcel Map/Subdivision/Condo-Maps" />
            <Tab value="COMMUNITY DEVELOPMENT" label="Community Development" />
            <Tab value="ADU DEVELOPMENT" label="ADU Development" />
            <Tab value="ADU CONVERSION" label="ADU Conversion" />
            <Tab value="AIRBNB" label="Airbnb" />
          </Tabs>
        )}
      </Box>
      {renderProjectCards(filteredProjects)}
    </Box>
  ) : null;
};

export default ProjectsListView;
