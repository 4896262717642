import React, { useEffect, useState } from 'react';
import { Box, Typography } from "@mui/material";
import Blog from './Blog';
import { fetchBlogs } from '../../api/blogApiClient';

const BlogPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchBlogs();
        if (data) {
          const sortedBlogs = data.sort((a, b) => b.id - a.id);
          setBlogs(sortedBlogs);
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
            <Typography
        style={{
          fontSize: "40px",
          // lineHeight: "16.8px",
          textAlign: "center",
          textTransform: "uppercase",
          fontWeight: 700,
          margin: "80px 0 20px 0",
          color: "white",
        }}
      >
        Blog
      </Typography>

      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        blogs.length > 0 ? (
          blogs.map((blog) => (
            <Blog key={blog.id} blog={blog} />
          ))
        ) : (
          <Typography>No blogs available</Typography>
        )
      )}
    </Box>
  );
};

export default BlogPage;
