import axios from "axios";
import { API_URL } from "../constants/urls";

export const fetchHeader = async () => {
  try {
    const response = await axios.get(`${API_URL}/header?populate=*`);

    const resBody = { ...response?.data?.data?.attributes };
    return resBody;
  } catch (err) {
    console.log(err);
  }
};
