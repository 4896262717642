import React from "react";
import {
  Box,
  Typography,
  Unstable_Grid2 as Grid,
  // useMediaQuery,
} from "@mui/material";
// import { ImageBlock, TextBlock } from "./components";

// const testData = [
//   {
//     subheadLabel: "Optional Subhead",
//     textBody:
//       "Trust in us to invest your money wisely in real estate winners, as we invest your money the same way we invest our own money.",
//     imageUrl:
//       "https://kaizen-capital.s3.us-west-2.amazonaws.com/testimonials_hero_3bdc16c0b0.jpg?updated_at=2023-02-15T04:51:02.131Z",
//   },
//   {
//     subheadLabel: "Optional Subhead",
//     textBody:
//       "Trust in us to invest your money wisely in real estate winners, as we invest your money the same way we invest our own money.",
//     imageUrl:
//       "https://kaizen-capital.s3.us-west-2.amazonaws.com/testimonials_hero_3bdc16c0b0.jpg?updated_at=2023-02-15T04:51:02.131Z",
//   },
// ];

// const Highlights = () => {
//   const isMinBrowserWidth = useMediaQuery("(min-width:760px)");

//   return (
//     <Grid
//       xs={12}
//       display="flex"
//       justifyContent="center"
//       alignItems="center"
//       flexDirection="column"
//     >
//       {testData && isMinBrowserWidth
//         ? testData.map((t, i) => {
//             return i % 2 === 1 ? (
//               <Box
//                 key={i}
//                 display="flex"
//                 width="100%"
//                 justifyContent="center"
//                 alignItems="center"
//                 flexWrap="wrap"
//               >
//                 <ImageBlock
//                   imageUrl={t.imageUrl}
//                   imageContainer={{
//                     display: "flex",
//                     justifyContent: "left",
//                   }}
//                 />
//                 <TextBlock data={t} />
//               </Box>
//             ) : (
//               <Box
//                 key={i}
//                 display="flex"
//                 width="100%"
//                 justifyContent="center"
//                 alignItems="center"
//                 flexWrap="wrap"
//               >
//                 <TextBlock data={t} />
//                 <ImageBlock
//                   imageUrl={t.imageUrl}
//                   imageContainer={{
//                     display: "flex",
//                     justifyContent: "end",
//                   }}
//                 />
//               </Box>
//             );
//           })
//         : testData.map((t, i) => {
//             return (
//               <Box
//                 key={i}
//                 display="flex"
//                 width="100%"
//                 justifyContent="center"
//                 alignItems="center"
//                 flexDirection="column"
//                 mb="20px"
//               >
//                 <ImageBlock
//                   imageUrl={t.imageUrl}
//                   imageContainer={{
//                     display: "flex",
//                     justifyContent: "center",
//                     paddingBottom: 0,
//                   }}
//                 />
//                 <TextBlock data={t} />
//               </Box>
//             );
//           })}
//     </Grid>
//   );
// };

const AboutUs = ({ loaded }) => {
  return loaded ? (
    <Box w="100%">
      <Box display="flex" justifyContent="center">
        <Grid container maxWidth={1000}>
          {/* Intro Title */}
          <Grid xs={12} textAlign="center" mt="80px">
            <Typography
              style={{
                fontSize: "40px",
                // lineHeight: "16.8px",
                textAlign: "center",
                textTransform: "uppercase",
                fontWeight: 700,
                marginBottom: "20px",
                color: "white",
              }}
            >
              Who We Are
            </Typography>
          </Grid>

          {/* Intro Message */}
          <Grid xs={12} display="flex" justifyContent="center" mb="20px">
            <Typography
              style={{
                width: "80%",
                fontSize: "clamp(1.8rem, 5vw, 3rem)",
                textAlign: "center",
                fontWeight: 700,
                lineHeight: "60px",
                color: "white",
              }}
            >
              {/* "Nan possesses a great mind, real estate makes so much sense to me
              when he's involved in the process." */}
            </Typography>
          </Grid>

          {/* Intro Hero */}
          <Grid
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <img
              style={{
                maxHeight: 560,
                maxWidth: "1250px",
                width: "100%",
                objectFit: "cover",
                height: "100%",
                zIndex: 1000,
              }}
              alt="hero"
              src="https://kaizen-capital.s3.us-west-2.amazonaws.com/1_K_Ko8gu_15eb5bd0d7.jpeg?updated_at=2023-09-02T21:01:17.584Z"
            />

            <Typography
              mt="16px"
              p="20px"
              style={{
                fontSize: "clamp(14px, 14px, 14px)",
                fontWeight: 400,
                lineHeight: "24px",
                letteSpacing: "0em",
                textAlign: "center",
              }}
            >
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
              nec ipsum at metus facilisis egestas. */}
            </Typography>
          </Grid>

          {/* Intro Backstory */}
          <Grid
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box p="20px">
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(14px, 14px, 14px)",
                  textAlign: "left",
                  fontWeight: 700,
                  lineHeight: "60px",
                  textTransform: "uppercase",
                }}
              >
                BACKSTORY
              </Typography>
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(14px, 14px, 14px)",
                  textAlign: "left",
                  fontWeight: 400,
                  lineHeight: "24px",
                }}
                sx={{ whiteSpace: "pre-line" }}
              >
                Prior to the 2008 crash, Nan was employed in the mortgage industry, where he witnessed the rapid rise and detrimental fall of numerous investors and institutions.
                <br />
                <br />
                A crucial lesson Nan learned from the crisis was the importance of investing based on sound fundamentals to guard against future downturns, rather than merely chasing appreciation.
                <br />
                <br />
                Once Nan accumulated enough savings, he began purchasing properties adhering to the guidelines he established post-2008, aiming to safeguard his hard-earned income from inflation and generate additional revenue through rental income. As Nan acquired more properties and continually refined his investment processes and principles, his success multiplied, reaching new heights of achievement.
                <br />
                <br />
                Gradually, Nan's achievements sparked the interest of friends and family, who approached him for investment guidance. While many lacked the capital for substantial investments, the increasing demand inspired Nan to develop a syndication approach. By pooling resources from friends and family, he ensured the combined capital was substantial enough to deploy in order to generate meaningful returns for all involved. This eventually led to the creation of Kaizen Capital.
              </Typography>
            </Box>

            <Box p="20px">
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(14px, 14px, 14px)",
                  textAlign: "left",
                  fontWeight: 700,
                  lineHeight: "60px",
                  textTransform: "uppercase",
                }}
              >
                Kaizen Capital: Founded on Trust and Thriving through Collaboration
              </Typography>
              <Typography
                style={{
                  color: "white",
                  fontSize: "clamp(14px, 14px, 14px)",
                  textAlign: "left",
                  fontWeight: 400,
                  lineHeight: "24px",
                  marginBottom: "50px"
                }}
                sx={{ whiteSpace: "pre-line" }}
              >
                Throughout his rapid rise to entrepreneurial success, Nan gained valuable insights into the world of real estate investing. His expertise now spans a wide range of areas, including building trustworthy relationships, effectively partnering with a diverse array of contractors, collaborating strategically with local governments, and adeptly pinpointing and executing lucrative investments while mitigating financial risks.
                <br />
                <br />
                Nan's guiding principle in establishing Kaizen Capital has been to focus on acquiring multifamily properties with below-market rents, value-add and development potential. By diligently increasing Net Operating Income and capitalizing on value-add opportunities, Nan has been able to maximize returns by actualizing capital events, enabling him to unlock the full potential of each investment. By continually reinvesting the profits into new opportunities, he has successfully scaled Kaizen Capital's portfolio, ensuring sustainable growth and exceptional results for clients in the ever-evolving real estate market.
                <br />
                <br />
                Leveraging the expertise of his dedicated in-house team of property managers and contractors, Nan has honed a winning formula for acquiring, leasing, enhancing, developing, and realizing capital events at project completion for both residential and commercial units. At Kaizen Capital, trust and collaboration are at the core of their success, driving exceptional results for clients in the real estate market.
              </Typography>
            </Box>
          </Grid>
          {/* Intro Highlights */}
          {/* <Highlights /> */}
        </Grid>
      </Box>
    </Box>
  ) : null;
};

export default AboutUs;
