import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Button, Grow, useMediaQuery } from "@mui/material";
import useOnScreen from "../../hooks/useOnScreen";

const ContentOverview = ({ data = {}, setLoaded, loaded, overviewId }) => {
  const ref = useRef(null);
  const isVisible = useOnScreen(ref, loaded[`contentOverview${overviewId}`]);
  const isMinWidthTrue = useMediaQuery("(min-width:1340px)");

  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setRendered(true);
    }
  }, [isVisible]);

  let textPos;
  let imagePos;
  switch (data.imagePosition) {
    case "left":
      textPos = 2;
      imagePos = 1;
      break;
    case "right":
    default:
      textPos = 1;
      imagePos = 2;
      break;
  }

  useEffect(() => {
    if (data && !loaded[`contentOverview${overviewId}`]) {
      setLoaded({ ...loaded, [`contentOverview${overviewId}`]: true });
    }
  }, [data]);

  return data ? (
    <Box
      ref={ref}
      style={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "flex-start",
      }}
    >
      {/** Text section */}
      <Box
        style={{
          display: "flex",
          order: textPos,
          flex: "1 1 40rem",
          justifyContent: "center",
          padding: "60px 30px 30px 30px",
          backgroundColor: '#181818',
        }}
      >
        <Grow in={rendered} style={{ transformOrigin: "0 0 0" }} timeout={2000}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              style={{
                color: "rgba(255, 255, 255, 0.5)",
                fontSize: "clamp(14px, 14px, 14px)",
                lineHeight: "16.8px",
                textTransform: "uppercase",
                fontWeight: 700,
                marginBottom: "20px",
                textAlign: "left",
              }}
            >
              {data.subText}
            </Typography>
            <Typography
              style={{
                maxWidth: "550px",
                color: 'white',
                fontSize: "clamp(18px, 40px, 32px)",
                fontWeight: 700,
                marginBottom: "20px",
                textAlign: "left",
                lineHeight: "32.8px",
              }}
            >
              {data.heading}
            </Typography>
            <Typography
              style={{
                maxWidth: "600px",
                color: 'white',
                fontSize: "clamp(18px, 18px, 18px)",
                lineHeight: "24px",
                textAlign: "left",
                marginBottom: "30px",
                whiteSpace: "pre-line",
              }}
            >
              {data.textBody}
            </Typography>
            {/* {data.callToAction && (
              <Button
                variant="contained"
                style={{
                  borderRadius: "50px",
                  textTransform: "capitalize",
                  alignSelf: isMinWidthTrue ? "initial" : "center",
                  color: 'white',
                  backgroundColor: 'rgb(38, 128, 56)',
                }}
              >
                {data.callToAction.label}
              </Button>
            )} */}
          </Box>
        </Grow>
      </Box>

      {/** Image section */}
      <Box
        style={{
          order: imagePos,
          display: "flex",
          flex: "1 1 40rem",
          height: data.height,
        }}
      >
        {data.image && (
          <img
            style={{
              height: data.height,
              maxHeight: data.height,
              objectFit: "cover",
              zIndex: 1000,
              width: "100%",
            }}
            alt="img"
            src={data.image}
          />
        )}
      </Box>
    </Box>
  ) : null;
};

export default ContentOverview;
