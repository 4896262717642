import React from "react";
import { Box, Typography } from "@mui/material";
import quotation from "../../assets/quotation.png";

const Testimonial = ({ data }) => {
  return (
    <Box
      style={{
        display: "flex",
        height: "366px",
        width: "405px",
        justifyContent: "flex-start",
        alignItems: "space-between",
        padding: "16px",
      }}
    >
      <Box
        style={{
          justifyContent: "inherit",
          alignItems: "inherit",
          height: "100%",
          width: "10%",
        }}
      >
        <img alt="quotation" src={quotation} width={40} />
      </Box>
      <Box
        style={{
          justifyContent: "inherit",
          alignItems: "inherit",
          height: "100%",
          width: "80%",
          marginLeft: "10px",
          marginRight: "10px",
        }}
      >
        <Box
          style={{
            height: "200px",
            maxHeight: "200px",
          }}
        >
          <Typography
            style={{
              textAlign: "left",
              display: "-webkit-box",
              maxWidth: "100%",
              WebkitLineClamp: 7,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              color: 'white'
            }}
          >
            {data.textBody}
          </Typography>
        </Box>
        <Box
          style={{
            height: "100px",
            width: "100px",
            borderRadius: "100px",
            backgroundColor: "gray",
          }}
        >
          <img
            src={data.image}
            alt="avatar"
            style={{
              height: "inherit",
              width: "inherit",
              borderRadius: "inherit",
              objectFit: "cover",
            }}
          />
        </Box>
        <Typography
          style={{ textAlign: "left", fontSize: "18px", fontWeight: 700, color: 'white' }}
        >
          {data.clientName}
        </Typography>
        {data.clientTitle && (
          <Typography style={{ textAlign: "left", color: "rgba(255, 255, 255, 0.5)" }}>
            {data.clientTitle}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Testimonial;
