import React, { useContext } from "react";
import { Typography, Card, CardContent, CardMedia, Button, Grid } from "@mui/material";
import { CartContext } from "./CartContext";

const ProductCard = ({ product }) => {
  const cart = useContext(CartContext);
  const productQuantity = cart.getProductQuantity(product.id);
  console.log(cart.items);

  return (
    <Card sx={{ maxWidth: 345, m: 2, boxShadow: 3, borderRadius: 2 }}>
      <CardMedia
        component="img"
        height="320"
        image={product.image}
        alt="Product Image"
        sx={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold', color: '#333' }}>
          {product.title}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          {product.description}
        </Typography>
        <Typography variant="body1" color="text.primary" sx={{ mb: 2, fontWeight: 'bold' }}>
          ${product.price}
        </Typography>
        {productQuantity > 0 ? (
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={6}>
              <Typography variant="body1" align="left" sx={{ fontWeight: 'bold' }}>
                In Cart: {productQuantity}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="outlined"
                color="success"
                onClick={() => cart.removeOneFromCart(product.id)}
                sx={{ minWidth: '36px' }}
              >
                -
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="outlined"
                color="success"
                onClick={() => cart.addOneToCart(product.id)}
                sx={{ minWidth: '36px' }}
              >
                +
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Button
            variant="outlined"
            color="success"
            onClick={() => cart.addOneToCart(product.id)}
            sx={{ width: '100%', fontWeight: 'bold' }}
          >
            Add to Cart
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default ProductCard;
