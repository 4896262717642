import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { fetchHeader } from "../../api/headerApiClient";
import Tab from "./Tab";
import SocialButton from "./SocialButton";
import logo from "../../assets/logo.png";
import { useLocation, useNavigate } from "react-router";
import { styled } from "@mui/material/styles";

const StyledTypography = styled(Typography)`
  ${() => {
    return `
    &:hover {
      border-bottom: 2px solid #1D1D1F;
    }`;
  }}
`;

const Header = ({ setLoaded, loaded }) => {
  const [headerData, setHeaderData] = useState(null);
  const isMinBrowserWidth = useMediaQuery("(min-width:600px)");
  const [anchorElNav, setAnchorElNav] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      const res = await fetchHeader();
      if (res && !headerData) {
        setHeaderData(res);
      }
    };
    fetch();
  });

  useEffect(() => {
    if (headerData && !loaded.header) {
      setLoaded({ ...loaded, header: true });
    }
  }, [headerData]);

  const onClickTab = (page) => {
    if (!page) return;
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const onClickMenuOption = (path) => {
    navigate(path);
    handleCloseNavMenu();
  };

  return headerData ? (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "calc(100vw - 20px)",
        maxWidth: "1350px",
        height: "70px",
      }}
    >
      {/** Left header elements */}
      <Box
        style={{
          display: "inherit",
          height: "inherit",
          alignItems: "inherit",
          gap: "20px",
          marginLeft: "20px",
        }}
      >
        <IconButton
          onClick={() => {
            if (pathname === "/") return;
            navigate("/");
          }}
        >
          <img
            alt="logo"
            src={logo}
            style={{ height: "50px", width: "50px" }}
          />
        </IconButton>

        {isMinBrowserWidth &&
          headerData.tabs.map((t) => (
            <Link key={t.id} style={{ textDecoration: "none" }} to={t.path}>
              <Tab label={t.label} path={t.path} />
            </Link>
          ))}
      </Box>

      {/** Right header elements */}
      <Box
        style={{
          display: "inherit",
          height: "inherit",
          alignItems: "inherit",
          gap: "20px",
          marginRight: "20px",
        }}
      >
        {!isMinBrowserWidth ? (
          <>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              style={{ color: 'white' }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {[
                { id: "home", path: "/", label: "Home" },
                ...headerData.tabs,
              ].map((t) => (
                <MenuItem
                  key={t.id}
                  onClick={() => onClickMenuOption(t.path)}
                  style={{ backgroundColor: pathname === t.path ? 'rgb(38, 128, 56)' : "white" }}
                >
                  <StyledTypography
                    color={pathname === t.path ? "white" : "#1D1D1F"}
                    textAlign="center"
                    textTransform="uppercase"
                    border="2px solid transparent"
                    fontWeight={pathname === t.path ? 700 : 400}
                  >
                    {t.label}
                  </StyledTypography>
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : (
          <Box display="none">
            <Tab
              label="Partner With Us"
              section="partner-with-us"
              onClick={onClickTab}
            />
            {headerData.socials.map((s) => (
              <SocialButton key={s.id} type={s.type} url={s.url} />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  ) : null;
};

export default Header;
