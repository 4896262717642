import axios from "axios";
import { API_URL } from "../constants/urls";

export const fetchContentOverviews = async () => {
  try {
    const response = await axios.get(`${API_URL}/content-overviews?populate=*`);

    return response?.data?.data?.map((d) => {
      return {
        id: d.id,
        ...d.attributes,
        image: d?.attributes?.image?.data?.attributes?.url,
      };
    });
  } catch (err) {
    console.log(err);
  }
};
