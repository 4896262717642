import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import BrowserForm from './BrowserForm';
import { send } from 'emailjs-com';
import useGetWindowDims from '../../hooks/useGetWindowDims';
import { isMobile } from 'react-device-detect';
import { css } from '@emotion/react';

const styles = {
  container: css`
    display: flex;
    width: 100%;
    gap: 100px;
    margin: 70px 0;
  `,
  infoContainer: css`
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 100%;
    gap: 18px;
  `,
  subText: css`
    font-size: 14px;
    font-weight: 700;
    color: #AAAAAA;
  `,
  heading: css`
    font-size: 40px;
    font-weight: 700;
    color: white;
    line-height: 50px;
  `,
  textBody: css`
    color: white;
    white-space: pre-line;
  `
}

const PartnerWithUs = () => {
  const { width } = useGetWindowDims();
  const [submitted, setSubmitted] = useState(false);

  const formMethods = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      emailAddress: '',
      investmentGoals: {},
      investmentRange: '',
      investmentTimeline: '',
      additionalDetails: ''
    }
  });

  const onSubmit = data => {
    setSubmitted(true);

    let goals = '';

    Object.values(data.investmentGoals).forEach(goal => goals += `${goal}, `)

    const emailBody = `
      Name: ${data.firstName} ${data.lastName}
      Phone: ${data.phoneNumber}
      Email: ${data.emailAddress}
      Investment goals: ${goals}
      Investment range: ${data.investmentRange}
      Investment timeline: ${data.investmentTimeline}

      Message:
      "${data.additionalDetails}"
    `;

    send(
      'service_y7mval9',
      'template_6o3b5ai',
      {
        from_name: `${data.firstName} ${data.lastName}`,
        to_name: 'Kaizen Capital',
        message: emailBody,
        reply_to: data.emailAddress
      },
      'kV4QfFtJuWPIXPTnl'
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
    })
    .catch((err) => {
      console.log('FAILED...', err);
    });
  };

  return (
    <Box>
      {isMobile || width < 1260 ? null : (
        <Box id="partner-with-us" sx={styles.container}>
          <Box sx={styles.infoContainer}>
            <Typography sx={styles.subText}>
              PARTNER WITH US
            </Typography>
            <Typography sx={styles.heading}>
              Build generational wealth using Kaizen Capital's proven real estate formula for success
            </Typography>
            <Typography sx={styles.textBody}>
              It's long been said that real estate is the safest and most successful way to build long-term generational wealth. But who has the bandwidth or know-how to scout, vet, purchase, renovate, manage, landlord, and sell real estate properties? We do. 
              <br />
              <br />
              Kaizen Capital can save you time, sweat, and money because we do the heavy lifting as your investment partner. We can help you avoid risking a large sum on an iffy single rental unit or home. By spreading your capital across multiple investment properties and benefitting from our economies of scale, we diminish the dangers facing many beginner investors. 
              <br />
              <br />
              Ready to diversify your investment portfolio, accelerate your retirement, grow your nest egg, and enjoy greater financial flexibility? Contact us today and learn how Nan Lin and his team of professionals can leverage their collective real estate expertise to make you money—with fewer risks and more rewards than virtually any other type of investment.
            </Typography>
          </Box>
          <BrowserForm
            formMethods={formMethods}
            onSubmit={onSubmit}
            submitted={submitted}
          />
        </Box>
      )}
    </Box>
  )
};

export default PartnerWithUs;
