const productsArray = [
  {
    id: "price_1POgI72LKUvAGBblvUqHs8EZ",
    title: "Shirt 1",
    price: "25.00",
    description: "Featuring logo one",
    image: "https://m.media-amazon.com/images/I/A13usaonutL._CLa%7C2140%2C2000%7C41I2sl3egsL.png%7C0%2C0%2C2140%2C2000%2B0.0%2C0.0%2C2140.0%2C2000.0_AC_UY1000_.png"
  },
  {
    id: "price_1POgIv2LKUvAGBblullA2DMa",
    title: "Shirt 2",
    price: "25.00",
    description: "Featuring logo two",
    image: "https://m.media-amazon.com/images/I/A13usaonutL._CLa%7C2140%2C2000%7C41I2sl3egsL.png%7C0%2C0%2C2140%2C2000%2B0.0%2C0.0%2C2140.0%2C2000.0_AC_UY1000_.png"
  },
]

const getProductData = (id) => {
  let productData = productsArray.find(product => product.id === id);

  if (productData === undefined) {
    console.log("Product data dones not exist for ID: " + id);
    return undefined;
  }

  return productData;
}

export { productsArray, getProductData };
