import React from 'react';
import { Box, Typography, Grid } from "@mui/material";
import BlogCarousel from './components/BlogCarousel';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const Blog = ({ blog }) => {
  const { BlogHeader, BlogBody, DatePosted, BlogCarouselContent, YouTubeLinks } = blog;

  const blogCarouselUrls = BlogCarouselContent
    .map(item => item?.formats?.large?.url)
    .filter(url => url !== undefined);

  const blogMedia = [
    ...blogCarouselUrls,
    ...YouTubeLinks.map(link => link.URL)
  ].sort(url => (url.includes('youtube.com') ? -1 : 1));

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="center">
        <Grid container maxWidth={1000} spacing={2}>
          <Grid item xs={12} textAlign="center" mt={{ xs: 2, md: 8 }}>
          {/* test */}
          </Grid>

          {BlogHeader && (
            <Grid item xs={12} textAlign="center" mt={{ xs: 2, md: 8 }}>
              <Typography
                sx={{
                  fontSize: { xs: "24px", md: "40px" },
                  textAlign: "center",
                  textTransform: "uppercase",
                  fontWeight: 700,
                  marginBottom: "20px",
                  color: "white",
                }}
              >
                {BlogHeader}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            {DatePosted && (
              <Typography
                sx={{
                  color: "white",
                  fontSize: "14px",
                  textAlign: "left",
                  fontWeight: 700,
                  lineHeight: "24px",
                  textTransform: "uppercase",
                  mb: 2,
                }}
              >
                {DatePosted}
              </Typography>
            )}

{BlogCarouselContent && BlogCarouselContent.length > 0 && (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    width="100%"
  >
    {blogMedia.length > 0 && (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width='calc(100vw - 20px)'
        maxWidth='1350px'
        mb={9}
      >
        <BlogCarousel blogMedia={blogMedia} />
      </Box>
    )}
  </Box>
)}

            {BlogBody && (
              <Typography
                sx={{
                  color: "white",
                  fontSize: "clamp(14px, 14px, 14px)",
                  textAlign: "left",
                  fontWeight: 400,
                  lineHeight: "24px",
                  mb: 5,
                  px: 2,
                  whiteSpace: "pre-line",
                  marginTop: "80px",
                }}
              >
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {BlogBody}
                </ReactMarkdown>
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Blog;
