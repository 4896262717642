import axios from "axios";
import { API_URL } from "../constants/urls";

export const fetchProjectDetails = async () => {
  try {
    const response = await axios.get(`${API_URL}/projects?populate=*`);
    return response.data;
  } catch (error) {
    throw new Error(`Error: ${error.response?.status} ${error.response?.statusText}`);
  }
};
