import React, { useEffect, useState } from "react";
import { Box, Typography, useMediaQuery, Fade } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledBox = styled(Box)`
  ${({ theme, ...props }) => {
    if (!props.canGrow) return "";
    let style = `
      transition: ${theme.transitions.create("all", {
        duration: theme.transitions.duration.standard,
      })};
      &:hover {
        flex: 0 1 40% !important;
      };
    `;
    return style;
  }}
`;

const InvestmentOpportunities = ({ setLoaded, loaded }) => {
  const isMinWidthTrue = useMediaQuery("(min-width:980px)");

  const [hoveredSection, setHoveredSection] = useState(1);

  useEffect(() => {
    if (!loaded.investmentOpportunities) {
      setLoaded({ ...loaded, investmentOpportunities: true });
    }
  }, [loaded]);

  return (
    <Box style={{ backgroundColor: '#181818' }}>
      <Box
        style={{
          fontSize: "40px",
          width: "100%",
          fontWeight: 700,
          textAlign: "center",
          margin: "80px 0 40px 0",
        }}
      >
        <Typography
          style={{
            fontSize: "40px",
            width: "100%",
            lineHeight: "48px",
            fontWeight: 700,
            textAlign: "center",
            marginBottom: "40px",
            color: 'white'
          }}
        >
          Choose an investment opportunity
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column"
        }}
        onMouseLeave={() => setHoveredSection(1)}
      >
        <Box
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-end",
            marginRight: `${isMinWidthTrue ? 0 : 15}px`,
            marginLeft: `${isMinWidthTrue ? 0 : 15}px`,
          }}
        >
          <StyledBox
            style={{
              flex: `0 1 ${isMinWidthTrue ? hoveredSection === 1 ? "40%" : "25%" : "100%"}`,
              backgroundColor: "#268038",
              height: "300px",
              cursor: "pointer",
            }}
            section={1}
            canGrow={isMinWidthTrue}
            onMouseEnter={() => setHoveredSection(1)}
          >
            <Box style={{ margin: "50px" }}>
              <Typography
                style={{
                  fontSize: "28px",
                  fontWeight: 700,
                  lineHeight: "33.6px",
                  color: "white",
                  width: "70px",
                  marginBottom: "20px",
                }}
              >
                Partnering options
              </Typography>
              <Fade in={hoveredSection === 1 || !isMinWidthTrue} timeout={{ enter: 1000 }}>
                <Typography
                  style={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "white",
                    maxWidth: "350px",
                    textAlign: "left",
                  }}
                >
                  Be a limited, general partner and co-invest with us on curated
                  investment opportunities.
                </Typography>
              </Fade>
            </Box>
          </StyledBox>
          <StyledBox
            style={{
              flex: `0 1 ${isMinWidthTrue ? "25%" : "100%"}`,
              backgroundColor: "#005713",
              height: "300px",
              cursor: "pointer",
            }}
            section={2}
            canGrow={isMinWidthTrue}
            onMouseEnter={() => setHoveredSection(2)}
          >
            <Box style={{ margin: "50px" }}>
              <Typography
                style={{
                  fontSize: "28px",
                  fontWeight: 700,
                  lineHeight: "33.6px",
                  color: "white",
                  width: "70px",
                  marginBottom: "20px",
                }}
              >
                Turnkey solutions
              </Typography>
                <Fade in={hoveredSection === 2 || !isMinWidthTrue} timeout={{ enter: 1000 }}>
                  <Typography
                    style={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "white",
                      maxWidth: "350px",
                      textAlign: "left",
                    }}
                  >
                    You provide the capital, we locate, purchase, develop, and lease/manage your properties for you.
                  </Typography>
                </Fade>
            </Box>
          </StyledBox>
          <StyledBox
            style={{
              flex: `0 1 ${isMinWidthTrue ? "25%" : "100%"}`,
              height: "300px",
              backgroundColor: "#202A25",
              cursor: "pointer",
            }}
            section={3}
            canGrow={isMinWidthTrue}
            onMouseEnter={() => setHoveredSection(3)}
          >
            <Box style={{ margin: "50px" }}>
              <Typography
                style={{
                  fontSize: "28px",
                  fontWeight: 700,
                  lineHeight: "33.6px",
                  color: "white",
                  width: "70px",
                  marginBottom: "20px",
                }}
              >
                Entrepreneurial options
              </Typography>
                <Fade in={hoveredSection === 3 || !isMinWidthTrue} timeout={{ enter: 1000 }}>
                  <Typography
                    style={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "white",
                      maxWidth: "350px",
                      textAlign: "left",
                    }}
                  >
                    Acquire one of our holdings and develop/sell/rent it yourself.
                  </Typography>
                </Fade>
            </Box>
          </StyledBox>
        </Box>
      </Box>
    </Box>
  );
};

export default InvestmentOpportunities;
