import React from "react";
import { Box, Typography } from "@mui/material";

const Stat = ({ bg, data }) => {
  return data ? (
    <Box
      style={{
        position: "relative",
        display: "flex",
        height: "120px",
        width: "250px",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        padding: "24px",
        // backgroundColor: 'white'
      }}
    >
      {/* <img
        alt="bg"
        src={bg}
        style={{ position: "absolute", objectFit: "contain", width: '250px', top: '0px' }}
      /> */}
      <Typography
        style={{
          position: "absolute",
          fontSize: "80px",
          fontWeight: 700,
          // textShadow: "2px 2px 0px #00CC2C",
          top: 0,
          right: '20px',
          color: 'white'
        }}
      >
        {data.value}
      </Typography>
      <Box
        style={{
          width: "250px",
          height: "36px",
          textAlign: "right",
          background: "rgba(255, 255, 255, 1)",
          boxShadow: "6px 3px 0px #00CC2C",
          zIndex: 10000
        }}
      >
        <Typography
          style={{
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "20px",
            color: "#1D1D1F",
            marginRight: '10px',
            marginTop: '4px'
          }}
        >
          {data.subText}
        </Typography>
        {data.subText2 ? (
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "20px",
              color: "#1D1D1F",
              marginRight: '10px'
            }}
          >
            {data.subText2}
          </Typography>
        ) : null}
      </Box>
    </Box>
  ) : null;
};

export default Stat;
