import { Box, Typography, Link } from '@mui/material';
import React from 'react';
import logo from '../../assets/logo.png';
import { css } from '@emotion/react';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 400px;
    width: 100%;
  `,
  companyName: css`
    font-size: 14px;
    font-weight: 700;
    color: white;
  `,
  text: css`
    font-size: 14px;
    line-height: 24px;
    color: white;
  `,
  link: css`
    font-size: 14px;
    color: white;
    text-decoration: none;
  `,
  linkContainer: css`
    display: flex;
    flex-direction: column;
    margin: 24px 0;
  `,
  linkSection: css`
    display: flex;
    flex-direction: row;
    height: 100%;
  `,
  copyrightText: css`
    font-size: 12px;
    color: white;
  `,
  infoContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  contactSection: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
  `,
  socialContainer: css`
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 24px 0;
  `,
  youTubeIcon: css`
    font-size: 28px;
    fill: white;
  `,
  instagramIcon: css`
    font-size: 24px;
    fill: white;
  `
}

const MobileFooter = () => {
return (
  <Box sx={styles.container}>
    <Box sx={styles.infoContainer}>
      <img src={logo} alt="logo" style={{ height: "80px", marginTop: '24px' }} />
      <Box sx={styles.linkContainer}>
        <Box sx={styles.linkSection}>
          <Typography sx={styles.text}>
            <Link href="/" sx={styles.link}>Home</Link> |&nbsp;
          </Typography>
          <Typography sx={styles.text}>
            <Link href="/who-we-are" sx={styles.link}>About Us</Link> |&nbsp;
          </Typography>
          <Typography sx={styles.text}>
            <Link href="/testimonials" sx={styles.link}>Testimonials</Link>
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.contactSection}>
        <Typography sx={styles.companyName}>Kaizen Capital, LLC</Typography>
        <Box>
          <Typography sx={styles.text}>P.O. Box 161751, Sacramento, CA 95816</Typography>
        </Box>
        <Typography sx={styles.text}>
          <Link
            href="mailto:info@kaizencapitalllc.com"
            sx={styles.link}
          >
            info@kaizencapitalllc.com
          </Link>
        </Typography>
      </Box>
    </Box>
    <Box sx={styles.socialContainer}>
      <Link
        href="https://youtube.com/nanunlimited"
        target="_blank"
        rel='noopener'
      >
        <YouTubeIcon sx={styles.youTubeIcon} />
      </Link>
      <Link
        href="https://instagram.com/kaizencapital"
        target="_blank"
        rel='noopener'
      >
        <InstagramIcon sx={styles.instagramIcon} />
      </Link>
    </Box>
    <Typography sx={styles.copyrightText}>
      {`© ${new Date().getFullYear()} Kaizen Capital, LLC`}
    </Typography>
  </Box>
  )
};

export default MobileFooter;
