import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Box } from '@mui/material';
import Item from './Item';

const BlogCarousel = ({ blogMedia }) => {
  const [carouselPlaying, setCarouselPlaying] = useState(true);

  const handleVideoPlay = () => setCarouselPlaying(false);
  const handleVideoPause = () => setCarouselPlaying(true);

  return (
    <Box width="100%" display="flex" justifyContent="center" alignItems="center">
      <Box width="100%" maxWidth="1000px">
        <Carousel autoPlay={carouselPlaying} stopAutoPlayOnHover={true}>
          {blogMedia.map((url, i) => (
            <Item
              key={i}
              src={url}
              onVideoPlay={handleVideoPlay}
              onVideoPause={handleVideoPause}
            />
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

export default BlogCarousel;
