import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";

const Tab = ({ label, section, onClick, path }) => {
  const loc = useLocation();

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        padding: "10px",
        height: "30px",
        cursor: "pointer",
      }}
      onClick={() => {
        if (section && onClick) {
          onClick(section);
        }
      }}
    >
      <Typography
        style={{
          position: 'relative',
          fontSize: "16px",
          fontWeight: 700,
          textTransform: "uppercase",
          lineHeight: "20px",
          color: "white",
          width: 'max-content'
        }}
      >
        {label}
        {!!loc && loc.pathname === path && (
          <Box position="absolute" bottom="-4px" width="100%" height="2px" bgcolor="#1D1D1F" />
        )}
      </Typography>
    </Box>
  );
};

export default Tab;
