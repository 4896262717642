import React from "react";
import { Box, ButtonBase } from "@mui/material";
import instagram from "../../assets/social-icons/instagram.png";
import facebook from "../../assets/social-icons/facebook.png";
import linkedin from "../../assets/social-icons/linkedin.png";
import youtube from "../../assets/social-icons/youtube.png";
import twitter from "../../assets/social-icons/twitter.png";

const SocialButton = ({ type, url }) => {
  const icons = {
    instagram,
    facebook,
    linkedin,
    youtube,
    twitter,
  };
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        // padding: "10px",
        height: "30px",
        cursor: "pointer",
      }}
    >
      <ButtonBase href={url} target="_blank" rel="noopener noreferrer">
        <img alt="type" src={icons[type]} width="30px" height="30px" />
      </ButtonBase>
    </Box>
  );
};

export default SocialButton;
