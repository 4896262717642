import { Box, Grid, Typography } from "@mui/material";

const Success = () => {

  return(
    <Box>
    <Grid xs={12} textAlign="center" mt="80px">
      <Typography
        style={{
          fontSize: "40px",
          textAlign: "center",
          textTransform: "uppercase",
          fontWeight: 700,
          marginBottom: "20px",
          color: "white",
        }}
      >
        Thank you for your purchase!
      </Typography>
      </Grid>
    </Box>
)}

export default Success;
