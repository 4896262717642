import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { Header, Home, AboutUs, Testimonials, Footer, Airbnb, Shop } from "./components";
import ProjectsDetailView from "./components/ProjectsComponent/ProjectsDetailView";
import ProjectsListView from "./components/ProjectsComponent/ProjectsListView";
import BlogPage from "./components/Blog/BlogPage";
import Cancel from "./components/Shop/Cancel";
import Success from "./components/Shop/Success";
import CartProvider from "./components/Shop/CartContext";

const INITIAL_LOADED_STATE = {
  header: true,
  hero: false,
  contentOverview1: false,
  byTheNumbers: false,
  contentOverview2: false,
  wordOfMouth: false,
  footer: true
};

const App = () => {
  const loc = useLocation();
  const [loaded, setLoaded] = useState(INITIAL_LOADED_STATE);

  useEffect(() => {
    setLoaded(INITIAL_LOADED_STATE);
  }, [loc]);

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#333333' }}>
      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '1350px', backgroundColor: '#181818' }}>
        <CartProvider>
          <Header setLoaded={setLoaded} loaded={loaded} />
          <Routes>
            <Route exact path="/" element={<Home setLoaded={setLoaded} loaded={loaded} />} />
            <Route path="/who-we-are" element={<AboutUs loaded={loaded} />} />
            <Route path="/testimonials" element={<Testimonials loaded={loaded} />} />
            <Route path="/airbnb" element={<Airbnb loaded={loaded} />} />
            <Route path="/blog" element={<BlogPage loaded={loaded} />} />
            <Route path="/shop" element={<Shop loaded={loaded} />} />
            <Route path="/projects" element={<ProjectsListView loaded={loaded} />} />
            <Route path="/projects/:projectName" element={<ProjectsDetailView loaded={loaded} />} />
            <Route path="/success" element={<Success loaded={loaded} />} />
            <Route path="/cancel" element={<Cancel loaded={loaded} />} />
          </Routes>
        </CartProvider>
        <Footer setLoaded={setLoaded} loaded={loaded} location={loc} />
      </Box>
    </Box>
  );
};

export default App;
