import axios from "axios";
import { API_URL } from "../constants/urls";

export const fetchHero = async () => {
  try {
    const response = await axios.get(`${API_URL}/hero?populate=*`);

    const resBody = {
      ...response?.data?.data?.attributes,
      backgroundImage:
        `${response?.data?.data?.attributes?.backgroundImage?.data?.attributes?.url}`,
    };
    return resBody;
  } catch (err) {
    console.log(err);
  }
};
