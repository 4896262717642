import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper, Box } from '@mui/material';

const IMG_HEIGHT = 667;

const Item = ({ src }) => {
  return (
    <Paper style={{ height: IMG_HEIGHT, minHeight: IMG_HEIGHT, width: '100%' }}>
      <img
        style={{
          maxHeight: IMG_HEIGHT,
          maxWidth: "1350px",
          width: "100%",
          objectFit: "contain",
          height: "100%",
          zIndex: 1000,
          backgroundColor: '#181818'
        }}
        alt="hero"
        src={src}
      />
    </Paper>
  )
};

const HeroCarousel = ({ imgUrls }) => {
  return (
    <Box width="100%">
      <Carousel autoPlay={true} stopAutoPlayOnHover={true}>
        {imgUrls.map( (url, i) => <Item key={url} src={url} /> )}
      </Carousel>
    </Box>
  );
};

export default HeroCarousel;
