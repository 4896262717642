import * as React from "react";
import {
  Box,
  Unstable_Grid2 as Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import nanPhoto from '../../assets/nan_lin_cropped.png';

export default function WhoWeAreFooter() {
  const isMinBrowserWidth = useMediaQuery("(max-width:900px)");

  return (
    <Box width="100%">
      <Grid container width="100%">
        <Grid
          item
          xs={12}
          md={6}
          p={isMinBrowserWidth ? `48px 0px 0px 0px` : `64px 64px 64px 64px`}
          display="flex"
          alignItems="center"
          justifyContent={isMinBrowserWidth ? "center" : "flex-end"}
        >
          <img
            style={{
              display: "block",
              position: "relative",
              maxHeight: 360,
              width: "360px",
              objectFit: "cover",
              borderRadius: "50%",
              height: "360px",
              zIndex: 1000,
            }}
            alt="team"
            src={nanPhoto}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          p={isMinBrowserWidth ? `48px 48px 48px 48px` : `0px`}
          display="flex"
          flexDirection="column"
          alignItems={isMinBrowserWidth ? "center" : "flex-start"}
          justifyContent="center"
        >
          <Typography
            style={{
              fontSize: "14px",
              lineHeight: "16.8px",
              textAlign: "left",
              textTransform: "uppercase",
              fontWeight: 700,
              marginBottom: "20px",
              color: "#fff",
              whiteSpace: "pre-line",
              width: isMinBrowserWidth ? "330px" : "520px",
            }}
          >
            Meet Nan Lin, the visionary force behind Kaizen Capital
          </Typography>

          <Typography
            style={{
              fontSize: "clamp(14px, 14px, 14px)",
              fontWeight: 400,
              lineHeight: "24px",
              letteSpacing: "0em",
              textAlign: "left",
              color: "#fff",
              whiteSpace: "pre-line",
              width: isMinBrowserWidth ? "330px" : "520px",
            }}
          >
            Effective leadership is crucial to the growth, prosperity, and
            longevity of any company. You can rest assured that our enterprise
            is led by one of the most knowledgeable and respected names in the
            industry: Nan Lin, who founded Kaizen Capital in 2012 as a one-man
            operation that has evolved into a robust real estate investment firm
            supported by a talented team of employees.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
